import React from "react";
import { Logo, newLogo } from "../assets";
import { useNavigate } from "react-router-dom";

function SideNav() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#181818] dark:bg-tertiaryBlackShade text-white font-Poppins">
      {/* Logo Section */}
      <div className="p-4 border-b border-gray-700">
        <div
          className="flex items-center space-x-3 hover:opacity-80 transition-opacity cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={Logo} className="h-8 w-8" alt="Logo" />
          <img src={newLogo} className="h-6" alt="Company Name" />
        </div>
      </div>

      {/* Navigation Links */}
      <nav className="p-4 space-y-2">
        <NavItem
          label="Dashboard"
          path="/"
          onClick={() => navigate("/")}
        />
        <NavItem
          label="Customers"
          path="/customers"
          onClick={() => navigate("/customers")}
        />
        <NavItem
          label="Trial Users"
          path="/trial-users"
          onClick={() => navigate("/trial-users")}
        />
      </nav>
    </div>
  );
}

// NavItem component for consistent styling
const NavItem = ({ label, onClick }) => (
  <div
    className="flex items-center px-4 py-3 rounded-lg cursor-pointer
               hover:bg-gray-700 transition-colors duration-200
               text-gray-300 hover:text-white"
    onClick={onClick}
  >
    {label}
  </div>
);

export default SideNav;
