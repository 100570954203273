import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFreeTrialUsers, addCustomer, postCreditsSubmit } from '../Service/api';
import {
  Card,
  Tooltip,
  Button,
  Input,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react';
import ToastNotification, {showToast} from './ToastNotification';

const FreeTrialUsersTable = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    organization: '',
    plan: '',
    duration: '',
    posts: 200,
    contact_credits: 100,
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { 
    isOpen: isCreditsModalOpen, 
    onOpen: onOpenCreditsModal, 
    onOpenChange: onOpenChangeCreditsModal 
  } = useDisclosure();
  const [creditsForm, setCreditsForm] = useState({
    posts: '',
    contact_credits: ''
  });

  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getFreeTrialUsers();
      setMetrics(response.payload);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (customer) => {
    navigate(`/customer-details/${customer.id}`, { state: { customer } });
  };

  const handlePlanSelect = (selectedPlan) => {
    setUserData((prevData) => ({ ...prevData, plan: selectedPlan }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async () => {
    console.log(userData);
    const res = await addCustomer(userData);
    console.log(res);
    if (res.success == false) {
      showToast(res.payload.error[0] || 'Operation Failed', 'error')
    }
    else {
      showToast(res.payload.payload || 'Operation Succesful', 'success')
    }
      
    setUserData({
      first_name: '',
      last_name: '',
      email: '',
      organization: '',
      plan: '',
      duration: '',
      posts: 200,
      contact_credits: 100,
    });
    onOpenChange(false);
  };

  const handleCreditsSubmit = async () => {
    try {
      await postCreditsSubmit({
        user_id: selectedCustomer.id,
        posts: parseInt(creditsForm.posts),
        contact_credits: parseInt(creditsForm.contact_credits)
      });
      fetchData();
      onOpenChangeCreditsModal(false);
      showToast('Credits added successfully', 'success');
    } catch (err) {
      console.error(err);
      showToast('Failed to Modify Plan', 'error');
    }
  };

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error.message}</div>;
  }

  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <ToastNotification />
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl text-white">Free Trial Users</h1>
        <div className="flex space-x-5">
          <div className="flex-grow p-1">
            <input
              type="search"
              className="bg-transparent text-white disabled:cursor-not-allowed outline-none border-none !w-full"
              placeholder="Search by Name or Email"
            />
          </div>
          <Button auto shadow bordered className="bg-gray-800 text-white">
            Search
          </Button>
          <Button
            auto
            shadow
            bordered
            className="bg-gray-800 text-white"
            onClick={onOpen}
          >
            Add Customer
          </Button>
        </div>
      </div>

      <div className="bg-gray-800 rounded-xl shadow-xl h-[calc(100vh-12rem)] overflow-hidden">
        <div className="overflow-y-auto h-full">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              <tr className="bg-gray-700">
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Customer Name</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Email</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Status</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Starting Date</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Validity</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Plan</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Posts</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white">Contact Credits</th>
                <th className="py-4 px-6 text-left text-base font-semibold text-white sticky right-0 bg-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {metrics.map((metric, index) => (
                <tr 
                  key={index} 
                  className="border-b border-gray-700 hover:bg-gray-750 transition-colors cursor-pointer"
                  onClick={() => handleRowClick(metric)}
                >
                  <td className="py-4 px-6 text-white text-lg">{metric.full_name}</td>
                  <td className="py-4 px-6 text-white text-lg">{metric.email}</td>
                  <td className="py-4 px-6 text-white text-lg">
                    <span
                      className={`py-1 px-2 rounded-full text-xs ${
                        metric.subscription_status === 'INACTIVE'
                          ? 'bg-red-500'
                          : 'bg-green-500'
                      }`}
                    >
                      {metric.subscription_status}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-white text-lg">
                    {metric.created_at ? metric.created_at.split('T')[0] : ''}
                  </td>
                  <td className="py-4 px-6 text-white text-lg">
                    {metric.free_trial_ending_at
                      ? metric.free_trial_ending_at.split('T')[0]
                      : ''}
                  </td>
                  <td className="py-4 px-6 text-white text-lg">{metric.plan}</td>
                  <td className="py-4 px-6 text-white text-lg">{metric.posts}</td>
                  <td className="py-4 px-6 text-white text-lg">{metric.contact_credits}</td>
                  <td className="py-4 px-6 sticky right-0 bg-gray-800">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button className="bg-blue-600 hover:bg-blue-700 text-white text-lg">Action</Button>
                      </DropdownTrigger>
                      <DropdownMenu 
                        aria-label="Static Actions"
                        className="bg-gray-800"
                      >
                        <DropdownItem 
                          key="credits" 
                          className="text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCustomer(metric);
                            onOpenCreditsModal();
                          }}
                        >
                          Modify Plan
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={isCreditsModalOpen}
        onOpenChange={onOpenChangeCreditsModal}
        className="bg-gray-800"
        size="sm"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="border-b border-gray-700 px-6 py-4">
                <h2 className="text-2xl font-semibold text-white">Modify Plan</h2>
              </ModalHeader>
              <ModalBody className="px-6 py-4 space-y-4">
                <Input
                  label="Posts"
                  type="number"
                  placeholder="Enter number of posts"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={creditsForm.posts}
                  onChange={(e) => setCreditsForm(prev => ({...prev, posts: e.target.value}))}
                />
                <Input
                  label="Contact Credits" 
                  type="number"
                  placeholder="Enter number of contact credits"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={creditsForm.contact_credits}
                  onChange={(e) => setCreditsForm(prev => ({...prev, contact_credits: e.target.value}))}
                />
              </ModalBody>
              <ModalFooter className="border-t border-gray-700 px-6 py-4">
                <Button
                  className="bg-gray-700 hover:bg-gray-600 text-white text-lg mr-2"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-blue-600 hover:bg-blue-700 text-white text-lg"
                  onClick={handleCreditsSubmit}
                >
                  Modify Plan
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {isOpen && (
        <Modal
          placement={'top-center'}
          size="lg"
          centered
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          isDismissable={false}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  <h1 className="text-xl">
                    Add a customer on{' '}
                    <span className="text-blue-500">SocialSense</span>
                  </h1>
                </ModalHeader>
                <ModalBody>
                  <Input
                    clearable
                    fullWidth
                    placeholder="John"
                    label="First Name"
                    className="text-white"
                    name="first_name"
                    value={userData.first_name}
                    onChange={handleInputChange}
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="Cena"
                    label="Last Name"
                    className="text-white"
                    name="last_name"
                    value={userData.last_name}
                    onChange={handleInputChange}
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="someone@example.com"
                    label="Email Address"
                    type="email"
                    className="text-white"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                  />
                  <Input
                    fullWidth
                    initialValue=""
                    label="Organization Name"
                    className="text-white"
                  />
                  <Dropdown>
                    <DropdownTrigger>
                      <Input
                        value={userData.plan || 'Select Plan'}
                        label="Plan"
                        className="text-white"
                      />
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Static Actions"
                      onAction={handlePlanSelect}
                    >
                      <DropdownItem key="Free Trial">Free Trial</DropdownItem>
                      <DropdownItem key="Premium Plan">Premium Plan</DropdownItem>
                      <DropdownItem key="Pro Plan">Pro Plan</DropdownItem>
                      <DropdownItem key="Team Plan">Team Plan</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Input
                    fullWidth
                    label="Choose Duration"
                    type="date"
                    className="text-white"
                    name="duration"
                    value={userData.duration}
                    onChange={handleInputChange}
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="Amount of Post Credits"
                    label="Post Credits"
                    type="number"
                    className="text-white"
                    name="posts"
                    value={userData.posts}
                    onChange={handleInputChange}
                  />
                  <Input
                    clearable
                    fullWidth
                    placeholder="Amount of Contact Credits"
                    label="Contact Credits"
                    type="number"
                    className="text-white"
                    name="contact_credits"
                    value={userData.contact_credits}
                    onChange={handleInputChange}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button auto flat color="error" onClick={onClose}>
                    Close
                  </Button>
                  <Button auto onClick={handleFormSubmit}>
                    Done
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default FreeTrialUsersTable;
