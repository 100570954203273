import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomersData, postCreditsSubmit, addCustomer } from '../Service/api';
import {
  Tooltip,
  Button,
  Input,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react';
import { Select, SelectItem } from '@nextui-org/react';
import ToastNotification, {showToast} from './ToastNotification';

const FreeTrialUsersTable = () => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { 
    isOpen: isCreditsModalOpen, 
    onOpen: onOpenCreditsModal, 
    onOpenChange: onOpenChangeCreditsModal 
  } = useDisclosure();
  const [creditsForm, setCreditsForm] = useState({
    posts: '',
    contact_credits: '',
    plan: ''
  });
  const [customerForm, setCustomerForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    organization: 'SocialSense',
    plan: '',
    duration: '',
    posts: '',
    contact_credits: ''
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getCustomersData();
      setMetrics(response.payload);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div className="text-white text-lg">Loading...</div>;
  }

  if (error) {
    return <div className="text-white text-lg">Error: {error.message}</div>;
  }

  const handleRowClick = (customer) => {
    navigate(`/customer-details/${customer.id}`, { state: { customer } });
  };

  const handleCreditsSubmit = async () => {
    try {
      await postCreditsSubmit({
        user_id: selectedCustomer.id,
        posts: parseInt(creditsForm.posts),
        contact_credits: parseInt(creditsForm.contact_credits),
        plan: creditsForm.plan
      });
      fetchData();
      onOpenChangeCreditsModal(false);
      showToast('Credits added successfully', 'success');
    } catch (err) {
      console.error(err);
      showToast('Failed to Modify Plan', 'error');
    }
  };

  const handleAddCustomer = async () => {
    try {
      const res = await addCustomer(customerForm);
      if (res.success === false) {
        showToast(res.payload.error[0] || 'Operation Failed', 'error')
      } else {
        showToast(res.payload.payload || 'Operation Successful', 'success')
      }
      fetchData();
      onOpenChange(false);
      setCustomerForm({
        first_name: '',
        last_name: '',
        email: '',
        organization: 'SocialSense',
        plan: '',
        duration: '',
        posts: '',
        contact_credits: ''
      });
    } catch (err) {
      console.error(err);
      showToast('Failed to add customer', 'error');
    }
  };

  const filteredMetrics = metrics.filter(metric => {
    const searchLower = searchTerm.toLowerCase();
    return (
      metric.full_name?.toLowerCase().includes(searchLower) ||
      metric.email?.toLowerCase().includes(searchLower) ||
      metric.plan?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <ToastNotification />
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-semibold text-white">Converted Customers</h1>
        <div className="flex items-center gap-4">
          <div className="relative">
            <input
              type="search"
              className="w-80 bg-gray-800 text-white text-lg rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Search by Name, Email or Plan"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <svg className="w-6 h-6 text-white absolute right-3 top-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <Button 
            className="bg-blue-600 hover:bg-blue-700 text-white text-lg px-4 py-2 rounded-lg transition-colors"
            onClick={onOpen}
          >
            Add Customer
          </Button>
        </div>
      </div>

      <div className="bg-gray-800 rounded-xl shadow-xl">
        <div className="relative">
          <div className="overflow-x-auto" style={{
            scrollbarWidth: 'thin',
            scrollbarColor: '#4B5563 #1F2937'
          }}>
            <div className="overflow-y-auto max-h-[calc(100vh-250px)]" style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#4B5563 #1F2937'
            }}>
              <table className="w-full">
                <thead className="sticky top-0 z-10">
                  <tr className="bg-gray-700">
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Customer Name</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Email</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Status</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Starting Date</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Validity</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Plan</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Posts</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white">Contact Credits</th>
                    <th className="py-4 px-6 text-left text-base font-semibold text-white sticky right-0 bg-gray-700">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMetrics.map((metric, index) => (
                    <tr 
                      key={index} 
                      className="border-b border-gray-700 hover:bg-gray-750 transition-colors cursor-pointer"
                      onClick={() => handleRowClick(metric)}
                    >
                      <td className="py-4 px-6 text-white text-lg">{metric.full_name}</td>
                      <td className="py-4 px-6 text-white text-lg">{metric.email}</td>
                      <td className="py-4 px-6 text-white text-lg">
                        <span
                          className={`py-1 px-2 rounded-full text-xs ${
                            metric.subscription_status === 'INACTIVE'
                              ? 'bg-red-500'
                              : 'bg-green-500'
                          }`}
                        >
                          {metric.subscription_status}
                        </span>
                      </td>
                      <td className="py-4 px-6 text-white text-lg">
                        {metric.created_at ? metric.created_at.split('T')[0] : ''}
                      </td>
                      <td className="py-4 px-6 text-white text-lg">
                        {metric.free_trial_ending_at
                          ? metric.free_trial_ending_at.split('T')[0]
                          : ''}
                      </td>
                      <td className="py-4 px-6 text-white text-lg">{metric.plan}</td>
                      <td className="py-4 px-6 text-white text-lg">{metric.posts}</td>
                      <td className="py-4 px-6 text-white text-lg">{metric.contact_credits}</td>
                      <td className="py-4 px-6 sticky right-0 bg-gray-800">
                        <Dropdown>
                          <DropdownTrigger>
                            <Button className="bg-blue-600 hover:bg-blue-700 text-white text-lg">Action</Button>
                          </DropdownTrigger>
                          <DropdownMenu 
                            aria-label="Static Actions"
                            className="bg-gray-800"
                          >
                            <DropdownItem 
                              key="credits" 
                              className="text-white"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCustomer(metric);
                                onOpenCreditsModal();
                              }}
                            >
                              Modify Plan
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isCreditsModalOpen}
        onOpenChange={onOpenChangeCreditsModal}
        className="bg-gray-800"
        size="sm"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="border-b border-gray-700 px-6 py-4">
                <h2 className="text-2xl font-semibold text-white">Modify Plan</h2>
              </ModalHeader>
              <ModalBody className="px-6 py-4 space-y-4">
                <Select
                  label="Plan"
                  placeholder="Select a plan"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={creditsForm.plan}
                  onChange={(e) => setCreditsForm(prev => ({...prev, plan: e.target.value}))}
                >
                  <SelectItem key="Starter Plan" value="Starter Plan">Starter Plan</SelectItem>
                  <SelectItem key="Premium Plan" value="Premium Plan">Premium Plan</SelectItem>
                  <SelectItem key="Pro Plan" value="Pro Plan">Pro Plan</SelectItem>
                  <SelectItem key="Team Plan" value="Team Plan">Team Plan</SelectItem>
                </Select>
                <Input
                  label="Posts"
                  type="number"
                  placeholder="Enter number of posts"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={creditsForm.posts}
                  onChange={(e) => setCreditsForm(prev => ({...prev, posts: e.target.value}))}
                />
                <Input
                  label="Contact Credits" 
                  type="number"
                  placeholder="Enter number of contact credits"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={creditsForm.contact_credits}
                  onChange={(e) => setCreditsForm(prev => ({...prev, contact_credits: e.target.value}))}
                />
              </ModalBody>
              <ModalFooter className="border-t border-gray-700 px-6 py-4">
                <Button
                  className="bg-gray-700 hover:bg-gray-600 text-white text-lg mr-2"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-blue-600 hover:bg-blue-700 text-white text-lg"
                  onClick={handleCreditsSubmit}
                >
                  Modify Plan
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="bg-gray-800"
        size="lg"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="border-b border-gray-700 px-6 py-4">
                <h2 className="text-2xl font-semibold text-white">Add Customer</h2>
              </ModalHeader>
              <ModalBody className="px-6 py-4 space-y-4">
                <Input
                  label="First Name"
                  placeholder="John"
                  type="text"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.first_name}
                  onChange={(e) => setCustomerForm(prev => ({...prev, first_name: e.target.value}))}
                />
                <Input
                  label="Last Name"
                  placeholder="Doe"
                  type="text"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.last_name}
                  onChange={(e) => setCustomerForm(prev => ({...prev, last_name: e.target.value}))}
                />
                <Input
                  label="Email Address"
                  placeholder="someone@example.com"
                  type="email"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.email}
                  onChange={(e) => setCustomerForm(prev => ({...prev, email: e.target.value}))}
                />
                <Input
                  label="Organization Name"
                  value="SocialSense"
                  readOnly
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.organization}
                  onChange={(e) => setCustomerForm(prev => ({...prev, organization: e.target.value}))}
                />
                <Select
                  label="Select Plan"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.plan}
                  onChange={(e) => setCustomerForm(prev => ({...prev, plan: e.target.value}))}
                >
                  <SelectItem key="starter" value="Starter Plan">Starter Plan</SelectItem>
                  <SelectItem key="premium" value="Premium Plan">Premium Plan</SelectItem>
                  <SelectItem key="pro" value="Pro Plan">Pro Plan</SelectItem>
                  <SelectItem key="team" value="Team Plan">Team Plan</SelectItem>
                </Select>
                <Input
                  label="Choose Duration"
                  type="date"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.duration}
                  onChange={(e) => setCustomerForm(prev => ({...prev, duration: e.target.value}))}
                />
                <Input
                  label="Amount of Post Credits"
                  placeholder="100"
                  type="number"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.posts}
                  onChange={(e) => setCustomerForm(prev => ({...prev, posts: e.target.value}))}
                />
                <Input
                  label="Contact Credits"
                  placeholder="50"
                  type="number"
                  className="bg-gray-700 rounded-lg text-white text-lg"
                  labelPlacement="outside"
                  value={customerForm.contact_credits}
                  onChange={(e) => setCustomerForm(prev => ({...prev, contact_credits: e.target.value}))}
                />
              </ModalBody>
              <ModalFooter className="border-t border-gray-700 px-6 py-4">
                <Button
                  className="bg-gray-700 hover:bg-gray-600 text-white text-lg mr-2"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-blue-600 hover:bg-blue-700 text-white text-lg"
                  onClick={handleAddCustomer}
                >
                  Add Customer
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FreeTrialUsersTable;
