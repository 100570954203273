export const firebaseConfig = {
  apiKey: "AIzaSyB40zktERyUmD_WS6feVgAtn6b9xZOYpC4",
  authDomain: "socialsense-admin-e9de7.firebaseapp.com",
  projectId: "socialsense-admin-e9de7",
  storageBucket: "socialsense-admin-e9de7.appspot.com",
  messagingSenderId: "267950837851",
  appId: "1:267950837851:web:47b9c0733d15ce0eda2f7e"
};

export const atomicPricing = {
  id:process.env.REACT_APP_DEV_ATOMICPRICING_ID,
  site:process.env.REACT_APP_DEV_ATOMICPRICING_SITE,
  pricingpage:process.env.REACT_APP_DEV_ATOMICPRICING_PRICING_PAGE,
  viewport_defaultheight:process.env.REACT_APP_DEV_ATOMICPRICING_VEIWPORT_DEFAULTHEIGHT,
  showcurrencydropdown:process.env.REACT_APP_DEV_ATOMICPRICING_SHOW_CURRENCY_DROPDOWN,
}

export const chargebeeURL =process.env.REACT_APP_DEV_CHARGEBEE_URL;

export const apiBaseURL = process.env.REACT_APP_DEV_BACKEND_BASE_URL;

export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
