import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomerDetails from '../components/CustomerDetails';

const CustomerDetailsPage = () => {
  const location = useLocation();
  const customer = location.state?.customer;
  const navigate = useNavigate();

  console.log(customer);

  return (
    <div className="bg-black min-h-screen p-6 overflow-y-auto">
      <button 
        onClick={() => navigate(-1)}
        className="bg-gray-800 text-white py-2 px-4 rounded-lg mb-4"
      >
        ← Back
      </button>
      <div className="text-white text-left mb-6">
        <h1 className="text-xl">Admin Dashboard</h1>
        <h1>A centralized platform for managing and monitoring operations, user activities, and data analytics.</h1>
      </div>
      {customer ? (
        <CustomerDetails customer={customer} />
      ) : (
        <div className="text-white">No customer selected</div>
      )}
    </div>
  );
};

export default CustomerDetailsPage;
